/****
BLOG PAGE
****/
.blog{
	h1.post-title{
		display: none;
	}
	.post-list{
		margin: 0;
		figcaption{
			min-height: 300px !important;
			background-size: contain!important;
            background-position: center center;
            background-repeat: no-repeat;
			&.col-4{
				h2 a{
					font-size: 18px;
				}
			}
		}
	}
}