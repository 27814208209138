/****
CONTACT PAGE
****/
.contact{
    .banner{
        .text1 {
            font-size: 60px;
        }
        .text2 {
            font-size: 85px;
        }
    }
    .post-content{
        @include media-query($on-laptop) {text-align: center;}
    }
    .post-title{
        display: none;
    }
    h4 {
        color: $pallet-color;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
    }
    .state{
        height:165px;
        background-size: contain; 
        background-repeat: no-repeat;
        background-position: center center; 
    }
    p{
        text-align: center;
    }
}
form{ 
    .row {
        margin-bottom: 10px;
    }
    #form-messages{
        text-align: center;
        font-size: 12px;
        color: $pallet-color;
        &.error{
            color: $brand-color;
        }
    }
    label {
        display: inline-table;
        text-align: right;
        position: relative;
        vertical-align: top;
        top: 7px;
        text-transform: uppercase;
        font-weight: bold;
        color: $brand-color;
        span{
            float: right;
            line-height: 0;
            font-size: 9px;
        }
        @include media-query($on-laptop) {
            text-align: left;
        }
    }
    input, textarea {
        margin: 0;
        border: 0;
        line-height: 1em;
        background:whitesmoke;
        color: $pallet-color;
        padding: 10px 0;
        &:-webkit-autofill, form textarea:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 30px black inset;
            -webkit-text-fill-color: $pallet-color !important;
        }
        &:focus{
            outline: none!important;
        }
    }
    input{
        padding: 10px 0;
        &[type="submit"] {
            padding: 10px;
            text-transform: uppercase;
            font-weight: bold;
            position: relative;
            margin: auto;
            top: 30px;
            left: 50%;
            width: 100px;
            margin-left: 10px;
            &:hover{
                color: $brand-color;
            }
            @include media-query($on-laptop) {
                left: 0;
            }
        }
    }
    textarea{
        margin: 0!important;
        position: relative;
        top: 10px;
        right: -9px;
        height: 90px;
    }
    button{
        padding: 10px;
        text-transform: uppercase;
        font-weight: bold;
        position: relative;
        margin: auto;
        background: #000;
        color: $pallet-color;
        border: none;
        &:hover{
            color: $brand-color;
        }
    }
}