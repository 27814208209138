/*
The main SCSS file for everything, yep
*/
/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0; }

/**
 * Basic styling
 */
body {
  font: 400 16px/1.5 "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #ffffff;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
.highlight {
  margin-bottom: 15px; }

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle; }

/**
 * Figures
 */
figure > img {
  display: block; }

figcaption {
  font-size: 14px; }

/**
 * Lists
 */
li > ul,
li > ol {
  margin-bottom: 0; }

ul, ol {
  list-style-type: none; }

/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 400; }

/**
 * Links
 */
a {
  color: #cc9900;
  text-decoration: none; }
  a:hover {
    text-decoration: none; }

/**
 * Blockquotes
 */
blockquote {
  color: #828282;
  border-left: 4px solid #e8e8e8;
  padding-left: 15px;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic; }
  blockquote > :last-child {
    margin-bottom: 0; }

/**
 * Code formatting
 */
pre,
code {
  font-size: 15px;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  background-color: #fff; }

code {
  padding: 1px 5px; }

pre {
  padding: 8px 12px;
  overflow-x: auto; }
  pre > code {
    border: 0;
    padding-right: 0;
    padding-left: 0; }

/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(1000px - (30px * 2));
  max-width: calc(1000px - (30px * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: 30px;
  padding-left: 30px; }
  @media screen and (max-width: 800px) {
    .wrapper {
      max-width: -webkit-calc(1000px - (30px));
      max-width: calc(1000px - (30px));
      padding-right: 15px;
      padding-left: 15px; } }

/**
 * Clearfix
 */
.wrapper:after, .footer-col-wrapper:after {
  content: "";
  display: table;
  clear: both; }

/**
 * Icons
 */
.icon > svg {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle; }
  .icon > svg path {
    fill: #828282; }

.center {
  display: block;
  margin: 0 auto 20px; }

.left {
  float: left;
  clear: left; }

.right {
  float: right;
  clear: right; }

/**
 * Syntax highlighting styles
 */
.highlight {
  background: #fff; }
  .highlighter-rouge .highlight {
    background: #eef; }
  .highlight .c {
    color: #998;
    font-style: italic; }
  .highlight .err {
    color: #a61717;
    background-color: #e3d2d2; }
  .highlight .k {
    font-weight: bold; }
  .highlight .o {
    font-weight: bold; }
  .highlight .cm {
    color: #998;
    font-style: italic; }
  .highlight .cp {
    color: #999;
    font-weight: bold; }
  .highlight .c1 {
    color: #998;
    font-style: italic; }
  .highlight .cs {
    color: #999;
    font-weight: bold;
    font-style: italic; }
  .highlight .gd {
    color: #000;
    background-color: #fdd; }
  .highlight .gd .x {
    color: #000;
    background-color: #faa; }
  .highlight .ge {
    font-style: italic; }
  .highlight .gr {
    color: #a00; }
  .highlight .gh {
    color: #999; }
  .highlight .gi {
    color: #000;
    background-color: #dfd; }
  .highlight .gi .x {
    color: #000;
    background-color: #afa; }
  .highlight .go {
    color: #888; }
  .highlight .gp {
    color: #555; }
  .highlight .gs {
    font-weight: bold; }
  .highlight .gu {
    color: #aaa; }
  .highlight .gt {
    color: #a00; }
  .highlight .kc {
    font-weight: bold; }
  .highlight .kd {
    font-weight: bold; }
  .highlight .kp {
    font-weight: bold; }
  .highlight .kr {
    font-weight: bold; }
  .highlight .kt {
    color: #458;
    font-weight: bold; }
  .highlight .m {
    color: #099; }
  .highlight .s {
    color: #d14; }
  .highlight .na {
    color: #008080; }
  .highlight .nb {
    color: #0086B3; }
  .highlight .nc {
    color: #458;
    font-weight: bold; }
  .highlight .no {
    color: #008080; }
  .highlight .ni {
    color: #800080; }
  .highlight .ne {
    color: #900;
    font-weight: bold; }
  .highlight .nf {
    color: #900;
    font-weight: bold; }
  .highlight .nn {
    color: #555; }
  .highlight .nt {
    color: #000080; }
  .highlight .nv {
    color: #008080; }
  .highlight .ow {
    font-weight: bold; }
  .highlight .w {
    color: #bbb; }
  .highlight .mf {
    color: #099; }
  .highlight .mh {
    color: #099; }
  .highlight .mi {
    color: #099; }
  .highlight .mo {
    color: #099; }
  .highlight .sb {
    color: #d14; }
  .highlight .sc {
    color: #d14; }
  .highlight .sd {
    color: #d14; }
  .highlight .s2 {
    color: #d14; }
  .highlight .se {
    color: #d14; }
  .highlight .sh {
    color: #d14; }
  .highlight .si {
    color: #d14; }
  .highlight .sx {
    color: #d14; }
  .highlight .sr {
    color: #009926; }
  .highlight .s1 {
    color: #d14; }
  .highlight .ss {
    color: #990073; }
  .highlight .bp {
    color: #999; }
  .highlight .vc {
    color: #008080; }
  .highlight .vg {
    color: #008080; }
  .highlight .vi {
    color: #008080; }
  .highlight .il {
    color: #099; }

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto; }
  @media only screen and (min-width: 400px) {
    .container {
      width: 80%; } }
  @media only screen and (min-width: 960px) {
    .container {
      width: 75%;
      max-width: 60rem; } }

.row {
  position: relative;
  width: 100%; }

.row [class^="col"] {
  float: left;
  margin: 0.5rem 2%;
  min-height: 0.125rem; }

.row::after {
  content: "";
  display: table;
  clear: both; }

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  width: 96%; }

.col-1-sm {
  width: 4.3333333333%; }

.col-2-sm {
  width: 12.6666666667%; }

.col-3-sm {
  width: 21%; }

.col-4-sm {
  width: 29.3333333333%; }

.col-5-sm {
  width: 37.6666666667%; }

.col-6-sm {
  width: 46%; }

.col-7-sm {
  width: 54.3333333333%; }

.col-8-sm {
  width: 62.6666666667%; }

.col-9-sm {
  width: 71%; }

.col-10-sm {
  width: 79.3333333333%; }

.col-11-sm {
  width: 87.6666666667%; }

.col-12-sm {
  width: 96%; }

@media only screen and (min-width: 800px) {
  .col-1 {
    width: 4.3333333333%; }
  .col-2 {
    width: 12.6666666667%; }
  .col-3 {
    width: 21%; }
  .col-4 {
    width: 29.3333333333%; }
  .col-5 {
    width: 37.6666666667%; }
  .col-6 {
    width: 46%; }
  .col-7 {
    width: 54.3333333333%; }
  .col-8 {
    width: 62.6666666667%; }
  .col-9 {
    width: 71%; }
  .col-10 {
    width: 79.3333333333%; }
  .col-11 {
    width: 87.6666666667%; }
  .col-12 {
    width: 96%; }
  .offset-col-1 {
    margin-left: 4.3333333333%; }
  .offset-col-2 {
    margin-left: 12.6666666667%; }
  .offset-col-3 {
    margin-left: 21%; }
  .offset-col-4 {
    margin-left: 29.3333333333%; }
  .offset-col-5 {
    margin-left: 37.6666666667%; }
  .offset-col-6 {
    margin-left: 46%; }
  .offset-col-7 {
    margin-left: 54.3333333333%; }
  .offset-col-8 {
    margin-left: 62.6666666667%; }
  .offset-col-9 {
    margin-left: 71%; }
  .offset-col-10 {
    margin-left: 79.3333333333%; }
  .offset-col-11 {
    margin-left: 87.6666666667%; }
  .offset-col-12 {
    margin-left: 96%; }
  .gutter-col-1 {
    margin-right: 4.3333333333%; }
  .gutter-col-2 {
    margin-right: 12.6666666667%; }
  .gutter-col-3 {
    margin-right: 21%; }
  .gutter-col-4 {
    margin-right: 29.3333333333%; }
  .gutter-col-5 {
    margin-right: 37.6666666667%; }
  .gutter-col-6 {
    margin-right: 46%; }
  .gutter-col-7 {
    margin-right: 54.3333333333%; }
  .gutter-col-8 {
    margin-right: 62.6666666667%; }
  .gutter-col-9 {
    margin-right: 71%; }
  .gutter-col-10 {
    margin-right: 79.3333333333%; }
  .gutter-col-11 {
    margin-right: 87.6666666667%; }
  .gutter-col-12 {
    margin-right: 96%; }
  .hidden-sm {
    display: block; } }

/**
* Page content
*/
.page-content {
  color: #333333;
  padding: 0 0 30px;
  flex: 1; }
  .page-content .post-title h1 {
    display: none; }

.page-heading {
  font-size: 20px; }

/**
* Post content
*/
.post-header {
  margin-bottom: 30px; }

.post-title {
  font-size: 42px;
  text-transform: uppercase;
  font-weight: bold; }
  @media screen and (max-width: 800px) {
    .post-title {
      font-size: 36px; } }

.post-content {
  margin-bottom: 30px; }
  .post-content h2 {
    font-size: 32px; }
    @media screen and (max-width: 800px) {
      .post-content h2 {
        font-size: 28px; } }
  .post-content h3 {
    font-size: 26px; }
    @media screen and (max-width: 800px) {
      .post-content h3 {
        font-size: 22px; } }
  .post-content h4 {
    font-size: 20px; }
    @media screen and (max-width: 800px) {
      .post-content h4 {
        font-size: 18px; } }
  .post-content img {
    display: block;
    margin: 10px auto 40px;
    max-height: 250px; }

/**
 * Site header
 **/
.site-header {
  border-top: 5px solid #424242;
  border-bottom: 1px solid #e8e8e8;
  min-height: 56px;
  position: relative;
  background-color: transparent;
  background-image: linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent);
  height: 100%;
  background-size: 50px 50px; }

.site-title {
  font-size: 26px;
  font-weight: bolder;
  line-height: 56px;
  letter-spacing: -1px;
  margin-bottom: 0;
  float: left;
  text-transform: uppercase;
  color: #cc9900; }

.menu-close {
  position: absolute;
  top: 5px;
  font-size: 30px;
  right: 22px;
  display: none; }

.site-nav {
  float: right;
  line-height: 56px; }
  .site-nav .menu-icon {
    display: none; }
  .site-nav .page-link {
    color: #e8e8e8;
    line-height: 1.5; }
    .site-nav .page-link:not(:last-child) {
      margin-right: 20px; }
  .site-nav .trigger li {
    list-style: none;
    display: inline-block;
    margin: 0 0 0 10px; }
    .site-nav .trigger li a {
      color: #31b2b0; }
  @media screen and (max-width: 800px) {
    .site-nav {
      position: absolute;
      top: 9px;
      right: 15px;
      background-color: #ffffff;
      border: 1px solid #e8e8e8;
      border-radius: 5px;
      text-align: right;
      z-index: 4; }
      .site-nav .menu-icon {
        display: block;
        float: right;
        width: 36px;
        height: 26px;
        line-height: 0;
        padding-top: 10px;
        text-align: center; }
        .site-nav .menu-icon > svg {
          width: 18px;
          height: 15px; }
          .site-nav .menu-icon > svg path {
            fill: #31b2b0; }
      .site-nav .trigger {
        clear: both;
        display: none;
        margin-top: 30px; }
        .site-nav .trigger li {
          display: block;
          margin: 0;
          text-align: center;
          padding: 0 20px; }
          .site-nav .trigger li:hover a {
            color: #cc9900; }
      .site-nav.active .trigger {
        display: block;
        padding-bottom: 5px; }
      .site-nav.active .menu-icon {
        display: none;
        z-index: 0; }
      .site-nav.active + .menu-close {
        display: block;
        z-index: 5; }
      .site-nav .page-link {
        display: block;
        padding: 5px 10px;
        margin-left: 20px; }
        .site-nav .page-link:not(:last-child) {
          margin-right: 0; } }

/**
* Site footer
*/
.site-footer {
  border-top: 1px solid #e8e8e8;
  padding: 30px 0;
  background-color: transparent;
  background-image: linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, 0.05) 25%, rgba(255, 255, 255, 0.05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, 0.05) 75%, rgba(255, 255, 255, 0.05) 76%, transparent 77%, transparent);
  height: 100%;
  background-size: 50px 50px; }
  .site-footer form {
    color: #cc9900; }
    .site-footer form input[type="image"] {
      background: none; }

.footer-heading {
  font-size: 18px;
  margin-bottom: 15px;
  color: #333333; }

.contact-list,
.social-media-list {
  list-style: none;
  margin-left: 0; }

.contact-list span {
  font-weight: normal;
  font-size: 14px; }

.footer-col-wrapper {
  font-size: 15px;
  color: #828282;
  margin-left: -15px; }

.footer-col {
  float: left;
  margin-bottom: 15px;
  padding-left: 15px; }

.footer-col-1 {
  width: -webkit-calc(35% - (30px / 2));
  width: calc(35% - (30px / 2)); }

.footer-col-2 {
  width: -webkit-calc(20% - (30px / 2));
  width: calc(20% - (30px / 2)); }

.footer-col-3 {
  width: -webkit-calc(45% - (30px / 2));
  width: calc(45% - (30px / 2)); }

@media screen and (max-width: 800px) {
  .footer-col-1,
  .footer-col-2 {
    width: -webkit-calc(50% - (30px / 2));
    width: calc(50% - (30px / 2)); }
  .footer-col-3 {
    width: -webkit-calc(100% - (30px / 2));
    width: calc(100% - (30px / 2)); } }

@media screen and (max-width: 400px) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (30px / 2));
    width: calc(100% - (30px / 2)); } }

/****
* Hero Banner
****/
.hero {
  height: auto;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
  padding-bottom: 40px;
  /*&.blog-page{
        background-color: $grey-color-dark;
        background-image: repeating-linear-gradient(transparent, transparent 50px, rgba(0,0,0,.4) 50px, rgba(0,0,0,.4) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.4) 63px, rgba(0,0,0,.4) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),
        repeating-linear-gradient(270deg, transparent, transparent 50px, rgba(0,0,0,.4) 50px, rgba(0,0,0,.4) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.4) 63px, rgba(0,0,0,.4) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),
        repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0,0,0,.2) 2px, rgba(0,0,0,.2) 3px, transparent 3px, transparent 5px, rgba(0,0,0,.2) 5px);
    }&.portfolio-page{
        background:
        radial-gradient(hsl(45, 100%, 40%) 4%, hsl(45, 100%, 20%) 9%, hsla(0, 100%, 20%, 0) 9%) 0 0,
        radial-gradient(hsl(45, 100%, 40%) 4%, hsl(45, 100%, 20%) 8%, hsla(0, 100%, 20%, 0) 10%) 50px 50px,
        radial-gradient(hsla(45, 100%, 43%, 0.8) 20%, hsla(45, 100%, 23%, 0)) 50px 0,
        radial-gradient(hsla(45, 100%, 40%, 0.8) 20%, hsla(45, 100%, 20%, 0)) 0 50px,
        radial-gradient(hsla(45, 100%, 30%, 1) 35%, hsla(45, 100%, 18%, 0) 60%) 50px 0,
        radial-gradient(hsla(45, 100%, 30%, 1) 35%, hsla(45, 100%, 16%, 0) 60%) 100px 50px,
        radial-gradient(hsla(45, 100%, 25%, 0.7), hsla(45, 100%, 20%, 0)) 0 0,
        radial-gradient(hsla(45, 100%, 25%, 0.7), hsla(45, 100%, 18%, 0)) 50px 50px,
        linear-gradient(45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0,
        linear-gradient(-45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0;
        background-color: $brand-color; 
        background-size: 100px 100px;
    }*/ }
  .hero.contact-page, .hero.blog-page, .hero.portfolio-page {
    background-color: #cc9900;
    background-image: repeating-linear-gradient(120deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 1px, transparent 1px, transparent 60px), repeating-linear-gradient(60deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1) 1px, transparent 1px, transparent 60px), linear-gradient(60deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1)), linear-gradient(120deg, rgba(0, 0, 0, 0.1) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 0.1));
    background-size: 70px 120px;
    height: 80px; }
    .hero.contact-page .banner .script, .hero.blog-page .banner .script, .hero.portfolio-page .banner .script {
      font-family: 'Lobster', cursive;
      color: #fff;
      text-align: center;
      font-size: 30px;
      position: relative;
      top: 35px;
      margin: 0; }
    .hero.contact-page .banner .script span, .hero.blog-page .banner .script span, .hero.portfolio-page .banner .script span {
      background-color: #000;
      padding: 5px 20px; }
    .hero.contact-page .banner .script:before, .hero.blog-page .banner .script:before, .hero.portfolio-page .banner .script:before {
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
      top: 50%;
      width: 100%;
      border-bottom: 3px solid #fff; }
  .hero.home-page {
    z-index: 3; }
    .hero.home-page:after {
      content: "";
      background: url(../images/austin.jpg);
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 1;
      background-position: right;
      background-size: cover;
      background-attachment: fixed; }
    .hero.home-page .logo-border {
      border-radius: 175px;
      border: 6px double #fff;
      width: 175px;
      position: relative;
      margin: auto;
      height: 175px;
      top: 30px;
      background: radial-gradient(black 15%, transparent 16%) 0 0, radial-gradient(black 15%, transparent 16%) 8px 8px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 0 1px, radial-gradient(rgba(255, 255, 255, 0.1) 15%, transparent 20%) 8px 9px;
      background-color: #282828;
      background-size: 16px 16px;
      z-index: 0; }
    .hero.home-page h1 {
      display: block;
      position: relative;
      max-width: 90%;
      font-family: 'Lobster', cursive;
      font-size: 80px;
      color: #cc9900;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      line-height: 1em;
      top: 15px;
      text-align: center;
      text-shadow: 2px 3px 0px #000;
      margin: auto; }
      @media screen and (max-width: 800px) {
        .hero.home-page h1 {
          font-size: 65px; } }
      @media screen and (max-width: 400px) {
        .hero.home-page h1 {
          font-size: 50px; } }
    .hero.home-page span.top-logo {
      display: block;
      margin: auto;
      text-align: center;
      top: 26px;
      position: relative;
      font-size: 21px;
      line-height: 1em;
      text-transform: uppercase;
      transform: scale(1, 1.4);
      text-shadow: 2px 2px #000;
      color: #ffffff; }
    .hero.home-page span.ribbon p {
      font-family: 'Montserrat Subrayada', sans-serif;
      display: block;
      margin: auto;
      position: relative;
      text-align: center;
      font-size: 21px;
      transform: scale(1.5, 1.5);
      color: #000; }
    .hero.home-page span.ribbon {
      text-align: center;
      top: 35px;
      background-color: #31b2b0;
      height: 30px;
      padding: 0 20px;
      display: block;
      position: relative;
      margin: auto;
      max-width: 300px; }
    .hero.home-page span.ribbon:before, .hero.home-page span.ribbon:after {
      content: '.';
      text-indent: -9999em;
      height: 0;
      width: 0;
      border-style: solid;
      border-width: 15px;
      position: absolute;
      left: -30px;
      top: 6px;
      display: block;
      z-index: -1; }
    .hero.home-page span.ribbon:before {
      border-color: #228987 #228987 #228987 transparent;
      /* bg color for left side of ribbon */ }
    .hero.home-page span.ribbon:after {
      border-color: #228987 transparent #228987 #228987;
      /* bg color for right side of ribbon */
      right: -30px;
      left: auto; }
    .hero.home-page span.middle-script {
      display: block;
      margin: auto;
      position: relative;
      top: 41px;
      text-align: center;
      background: black;
      width: 100px;
      border: 2px solid #cc9900;
      border-radius: 10px;
      color: #ffffff; }
      .hero.home-page span.middle-script:before {
        border-top: 6px double #cc9900;
        content: "";
        position: absolute;
        top: 11px;
        width: 200%;
        z-index: -1;
        left: -50%; }
    .hero.home-page span.bottom-logo {
      position: relative;
      top: 44px;
      text-align: center;
      display: block;
      transform: scale(1.5, 2);
      text-shadow: 2px 2px #000;
      color: #ffffff; }
  .hero .banner {
    position: relative;
    z-index: 2; }

/**
* HOME Page
*/
.home h1 {
  font-size: 45px;
  font-weight: bolder;
  line-height: 56px;
  letter-spacing: -1px;
  text-transform: uppercase;
  color: #31b2b0;
  padding: 20px 0; }
  @media screen and (max-width: 800px) {
    .home h1 {
      font-size: 24px;
      text-align: center; } }

.home .service h2 {
  background-color: #cc9900;
  color: #fff; }
  .home .service h2 i.fa {
    color: #fff;
    font-size: 20px;
    margin: 0 20px; }
  .home .service h2 span {
    font-size: 20px; }

.home .service:hover h2 span {
  background-position: -100% 0; }

@media screen and (max-width: 800px) {
  .home .service p {
    margin: 15px 40px; } }

.featured-portfolio figure {
  height: 250px;
  position: relative;
  z-index: 0; }
  .featured-portfolio figure:hover .overlay, .featured-portfolio figure:active .overlay {
    width: 50%;
    height: 50%;
    margin-top: 40px; }
  .featured-portfolio figure .overlay {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    transition: all .5s;
    z-index: 1; }
  .featured-portfolio figure figcaption {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }
    .featured-portfolio figure figcaption header {
      display: block;
      color: #cc9900;
      text-align: center;
      line-height: 1em;
      font-size: 16px;
      margin-top: 10px; }
    .featured-portfolio figure figcaption span {
      position: absolute;
      bottom: 30px;
      width: 90%;
      text-align: center;
      margin-left: 5%; }
    .featured-portfolio figure figcaption footer {
      position: absolute;
      bottom: 5px;
      right: 10px;
      font-size: 16px;
      color: #31b2b0; }

.latest-post {
  display: flex; }
  @media screen and (max-width: 800px) {
    .latest-post {
      display: block; } }

.post-list {
  display: inline-block;
  margin: 20px;
  list-style: none;
  position: relative; }
  @media screen and (max-width: 800px) {
    .post-list {
      display: block;
      margin: auto; } }
  .post-list > figcaption {
    margin-bottom: 30px;
    background-size: contain !important;
    background-position: center center;
    background-repeat: no-repeat; }
    .post-list > figcaption .post-title {
      position: relative;
      background-color: rgba(0, 0, 0, 0.6);
      transition: all .5s; }
      .post-list > figcaption .post-title h2 {
        display: block;
        text-align: center;
        line-height: 1em;
        padding: 15px 20px; }
      .post-list > figcaption .post-title .excerpt {
        font-size: 16px;
        text-align: center;
        padding-bottom: 20px; }
      .post-list > figcaption .post-title .link-icon {
        position: absolute;
        top: 15px;
        left: 5px;
        color: #cc9900;
        font-size: 15px;
        visibility: hidden; }
    .post-list > figcaption:hover .post-title {
      background-color: black; }
    .post-list > figcaption:hover .link-icon {
      visibility: visible; }
  .post-list.col-8 > figcaption {
    height: calc(100% - 30px); }
    @media screen and (max-width: 800px) {
      .post-list.col-8 > figcaption {
        height: 180px; } }
  .post-list.col-4 > figcaption {
    height: 120px; }
    @media screen and (max-width: 800px) {
      .post-list.col-4 > figcaption {
        height: 180px; } }
    .post-list.col-4 > figcaption h2 {
      font-size: 16px; }
      @media screen and (max-width: 800px) {
        .post-list.col-4 > figcaption h2 {
          font-size: 24px; } }

.post-meta {
  font-size: 14px; }

.post-link {
  display: block;
  font-size: 24px;
  line-height: 1.4em; }

.date {
  color: #cc9900;
  display: block;
  font-size: 16px;
  position: relative;
  text-align: center;
  z-index: 1; }
  .date:before {
    border-top: 4px double #cc9900;
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    z-index: -1; }
  .date span {
    background: #000000;
    padding: 0 10px;
    text-transform: uppercase;
    display: inline-block;
    border-radius: 7px;
    border: 1px solid #cc9900;
    z-index: 1; }

.post-content .post-list li {
  background-size: cover;
  min-height: 250px; }

/****
CONTACT PAGE
****/
.contact .banner .text1 {
  font-size: 60px; }

.contact .banner .text2 {
  font-size: 85px; }

@media screen and (max-width: 800px) {
  .contact .post-content {
    text-align: center; } }

.contact .post-title {
  display: none; }

.contact h4 {
  color: #31b2b0;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold; }

.contact .state {
  height: 165px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center; }

.contact p {
  text-align: center; }

form .row {
  margin-bottom: 10px; }

form #form-messages {
  text-align: center;
  font-size: 12px;
  color: #31b2b0; }
  form #form-messages.error {
    color: #cc9900; }

form label {
  display: inline-table;
  text-align: right;
  position: relative;
  vertical-align: top;
  top: 7px;
  text-transform: uppercase;
  font-weight: bold;
  color: #cc9900; }
  form label span {
    float: right;
    line-height: 0;
    font-size: 9px; }
  @media screen and (max-width: 800px) {
    form label {
      text-align: left; } }

form input, form textarea {
  margin: 0;
  border: 0;
  line-height: 1em;
  background: whitesmoke;
  color: #31b2b0;
  padding: 10px 0; }
  form input:-webkit-autofill, form input form textarea:-webkit-autofill, form textarea:-webkit-autofill, form textarea form textarea:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px black inset;
    -webkit-text-fill-color: #31b2b0 !important; }
  form input:focus, form textarea:focus {
    outline: none !important; }

form input {
  padding: 10px 0; }
  form input[type="submit"] {
    padding: 10px;
    text-transform: uppercase;
    font-weight: bold;
    position: relative;
    margin: auto;
    top: 30px;
    left: 50%;
    width: 100px;
    margin-left: 10px; }
    form input[type="submit"]:hover {
      color: #cc9900; }
    @media screen and (max-width: 800px) {
      form input[type="submit"] {
        left: 0; } }

form textarea {
  margin: 0 !important;
  position: relative;
  top: 10px;
  right: -9px;
  height: 90px; }

form button {
  padding: 10px;
  text-transform: uppercase;
  font-weight: bold;
  position: relative;
  margin: auto;
  background: #000;
  color: #31b2b0;
  border: none; }
  form button:hover {
    color: #cc9900; }

/****
BLOG PAGE
****/
.blog h1.post-title {
  display: none; }

.blog .post-list {
  margin: 0; }
  .blog .post-list figcaption {
    min-height: 300px !important;
    background-size: contain !important;
    background-position: center center;
    background-repeat: no-repeat; }
    .blog .post-list figcaption.col-4 h2 a {
      font-size: 18px; }

/****
PORTFOLIO PAGE
****/
.portfolio .post-title {
  display: none; }

/****
BLOG PAGE
****/
.about h1.post-title {
  display: none; }

@media screen and (max-width: 800px) {
  .about .post-content {
    text-align: center; } }

/****
PAYMENT PAGE
****/
.payment {
  /**
	 * The CSS shown here will not be introduced in the Quickstart guide, but shows
	 * how you can use CSS to style your Element's container.
	 */ }
  .payment .StripeElement {
    background-color: white;
    height: 40px;
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease; }
  .payment .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df; }
  .payment .StripeElement--invalid {
    border-color: #fa755a; }
  .payment .StripeElement--webkit-autofill {
    background-color: #fefde5 !important; }

.author img {
  max-height: 100px;
  border-radius: 200px;
  display: inline-block;
  float: left;
  margin: 0 20px; }

.imgal-container {
  line-height: 0;
  -webkit-column-count: 5;
  -webkit-column-gap: 0;
  -moz-column-count: 5;
  -moz-column-gap: 0;
  column-count: 5;
  column-gap: 0; }

.imgal-img {
  width: 100%;
  height: auto;
  transition: filter .2s; }

.imgal-img:hover {
  -webkit-filter: opacity(80%);
  filter: opacity(80%);
  cursor: pointer; }

.imgal-modal {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000;
  color: #fff;
  vertical-align: middle;
  height: 100vh;
  width: 100vw; }

#imgal-modal-close {
  display: inline;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  cursor: pointer; }

.imgal-modal-img {
  width: 100%;
  height: 100%;
  object-fit: contain; }

@media only screen and (max-width: 768px) {
  .imgal-container {
    line-height: 0;
    -webkit-column-count: 2;
    -webkit-column-gap: 0;
    -moz-column-count: 2;
    -moz-column-gap: 0;
    column-count: 2;
    column-gap: 0; } }

@media only screen and (min-width: 768px) {
  .imgal-container {
    line-height: 0;
    -webkit-column-count: 3;
    -webkit-column-gap: 0;
    -moz-column-count: 3;
    -moz-column-gap: 0;
    column-count: 3;
    column-gap: 0; } }

@media only screen and (min-width: 992px) {
  .imgal-container {
    line-height: 0;
    -webkit-column-count: 4;
    -webkit-column-gap: 0;
    -moz-column-count: 4;
    -moz-column-gap: 0;
    column-count: 4;
    column-gap: 0; } }

@media only screen and (min-width: 1200px) {
  .imgal-container {
    line-height: 0;
    -webkit-column-count: 5;
    -webkit-column-gap: 0;
    -moz-column-count: 5;
    -moz-column-gap: 0;
    column-count: 5;
    column-gap: 0; } }

.post-content .imgal-container img {
  max-height: none; }

.imgal-modal {
  z-index: 5; }
