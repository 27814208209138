@charset "utf-8";

// BASE STYLES
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;
$spacing-unit:     30px;

// COLORS
$text-color:       #ffffff;
$background-color: #ffffff;
$brand-color:      #cc9900;
$pallet-color:     #31b2b0;
$pallet-dark:      #228987;
$transparent:      (255,0,0,0);

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// WIDTHS
$content-width:    1000px;
$on-palm:          400px;
$on-laptop:        800px;
$on-desktop:       960px;

// MIXINS
@mixin media-query($device) {
	@media screen and (max-width: $device) {
		@content;
	}
}