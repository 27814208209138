/****
BLOG PAGE
****/
.about{
	h1.post-title{
		display: none;
	}
	.post-content{
		@include media-query($on-laptop) {text-align: center;}
	}
}