/****
* Hero Banner
****/
.hero{
    height: auto;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
    padding-bottom: 40px;
    &.contact-page, &.blog-page, &.portfolio-page{
        background-color: $brand-color;
        background-image:
        repeating-linear-gradient(120deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
        repeating-linear-gradient(60deg, rgba(255,255,255,.1), rgba(255,255,255,.1) 1px, transparent 1px, transparent 60px),
        linear-gradient(60deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1)),
        linear-gradient(120deg, rgba(0,0,0,.1) 25%, transparent 25%, transparent 75%, rgba(0,0,0,.1) 75%, rgba(0,0,0,.1));
        background-size: 70px 120px;
        height: 80px;
        .banner{
            .script {
                font-family: 'Lobster', cursive;
                color: #fff;
                text-align: center;
                font-size: 30px;
                position: relative;
                top:35px;
                margin:0;
            }
            .script span {
                background-color: #000;
                padding: 5px 20px;
            }
            .script:before {
                content:"";
                display: block;
                position: absolute;
                z-index:-1;
                top: 50%;
                width: 100%;
                border-bottom: 3px solid #fff;
            }
        }
    }
    &.home-page{
        z-index: 3;
        &:after{
            content: "";
            background: url(../images/austin.jpg);
            opacity: 0.5;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: 1;
            background-position: right;
            background-size: cover;   
            background-attachment: fixed;
        }
        .logo-border{
            border-radius: 175px;
            border: 6px double #fff;
            width: 175px;
            position: relative;
            margin:auto;
            height: 175px;
            top: 30px;
            background:
            radial-gradient(black 15%, transparent 16%) 0 0,
            radial-gradient(black 15%, transparent 16%) 8px 8px,
            radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
            radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
            background-color:#282828;
            background-size:16px 16px;
            z-index:0;
        }
        h1{
            display: block;
            position: relative;
            max-width: 90%;
            font-family: 'Lobster', cursive;
            font-size: 80px;
            color: $brand-color;
            -webkit-transform: rotate(-3deg);
            -moz-transform: rotate(-3deg);
            line-height: 1em;
            top: 15px;
            text-align: center;
            text-shadow: 2px 3px 0px #000;
            margin:auto;
            @include media-query($on-laptop) {
                font-size:65px;
            }
            @include media-query($on-palm) {
                font-size:50px;
            }
        }
        span.top-logo {
            display: block;
            margin: auto;
            text-align: center;
            top: 26px;
            position: relative;
            font-size: 21px;
            line-height: 1em;
            text-transform: uppercase;
            transform: scale(1, 1.4);
            text-shadow: 2px 2px #000;
            color:#ffffff;
        }
        span.ribbon p{
            font-family: 'Montserrat Subrayada', sans-serif;
            display: block;
            margin: auto;
            position: relative;
            text-align: center;
            font-size: 21px;
            transform: scale(1.5,1.5);
            color:#000;
        }
        span.ribbon{
            text-align: center;
            top: 35px;
            background-color: $pallet-color;
            height: 30px;
            padding: 0 20px;
            display: block;
            position: relative;
            margin: auto;
            max-width: 300px;
        }
        span.ribbon:before, span.ribbon:after{
            content: '.';
            text-indent: -9999em;
            height: 0;
            width: 0;
            border-style: solid;
            border-width: 15px;
            position: absolute;
            left: -30px;
            top: 6px;
            display: block;
            z-index: -1;
        }

        span.ribbon:before{
            border-color: $pallet-dark $pallet-dark $pallet-dark transparent; /* bg color for left side of ribbon */
        }

        span.ribbon:after{
            border-color: $pallet-dark transparent $pallet-dark $pallet-dark; /* bg color for right side of ribbon */
            right: -30px;
            left: auto;
        }
        span.middle-script {
            display: block;
            margin: auto;
            position: relative;
            top: 41px;
            text-align: center;
            background: black;
            width: 100px;
            border: 2px solid $brand-color;
            border-radius: 10px;
            color:#ffffff;
            &:before {
                border-top: 6px double $brand-color;
                content: "";
                position: absolute;
                top: 11px;
                width: 200%;
                z-index: -1;
                left: -50%;
            }
        }
        span.bottom-logo {
            position: relative;
            top: 44px;
            text-align: center;
            display: block;
            transform: scale(1.5,2);
            text-shadow: 2px 2px #000;
            color:#ffffff;
        }
    }
    /*&.blog-page{
        background-color: $grey-color-dark;
        background-image: repeating-linear-gradient(transparent, transparent 50px, rgba(0,0,0,.4) 50px, rgba(0,0,0,.4) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.4) 63px, rgba(0,0,0,.4) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),
        repeating-linear-gradient(270deg, transparent, transparent 50px, rgba(0,0,0,.4) 50px, rgba(0,0,0,.4) 53px, transparent 53px, transparent 63px, rgba(0,0,0,.4) 63px, rgba(0,0,0,.4) 66px, transparent 66px, transparent 116px, rgba(0,0,0,.5) 116px, rgba(0,0,0,.5) 166px, rgba(255,255,255,.2) 166px, rgba(255,255,255,.2) 169px, rgba(0,0,0,.5) 169px, rgba(0,0,0,.5) 179px, rgba(255,255,255,.2) 179px, rgba(255,255,255,.2) 182px, rgba(0,0,0,.5) 182px, rgba(0,0,0,.5) 232px, transparent 232px),
        repeating-linear-gradient(125deg, transparent, transparent 2px, rgba(0,0,0,.2) 2px, rgba(0,0,0,.2) 3px, transparent 3px, transparent 5px, rgba(0,0,0,.2) 5px);
    }&.portfolio-page{
        background:
        radial-gradient(hsl(45, 100%, 40%) 4%, hsl(45, 100%, 20%) 9%, hsla(0, 100%, 20%, 0) 9%) 0 0,
        radial-gradient(hsl(45, 100%, 40%) 4%, hsl(45, 100%, 20%) 8%, hsla(0, 100%, 20%, 0) 10%) 50px 50px,
        radial-gradient(hsla(45, 100%, 43%, 0.8) 20%, hsla(45, 100%, 23%, 0)) 50px 0,
        radial-gradient(hsla(45, 100%, 40%, 0.8) 20%, hsla(45, 100%, 20%, 0)) 0 50px,
        radial-gradient(hsla(45, 100%, 30%, 1) 35%, hsla(45, 100%, 18%, 0) 60%) 50px 0,
        radial-gradient(hsla(45, 100%, 30%, 1) 35%, hsla(45, 100%, 16%, 0) 60%) 100px 50px,
        radial-gradient(hsla(45, 100%, 25%, 0.7), hsla(45, 100%, 20%, 0)) 0 0,
        radial-gradient(hsla(45, 100%, 25%, 0.7), hsla(45, 100%, 18%, 0)) 50px 50px,
        linear-gradient(45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0,
        linear-gradient(-45deg, hsla(0, 100%, 20%, 0) 49%, hsla(0, 100%, 0%, 1) 50%, hsla(0, 100%, 20%, 0) 70%) 0 0;
        background-color: $brand-color; 
        background-size: 100px 100px;
    }*/
    .banner{
        position: relative;
        z-index: 2;
    }
}