/**
 * Site header
 **/
 .site-header {
    border-top: 5px solid $grey-color-dark;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;
    // Positioning context for the mobile navigation icon
    position: relative;
    background-color: transparent;
    background-image:       linear-gradient(0deg, transparent 24%, rgba(255, 255, 255, .05) 25%, rgba(255, 255, 255, .05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, .05) 75%, rgba(255, 255, 255, .05) 76%, transparent 77%, transparent), linear-gradient(90deg, transparent 24%, rgba(255, 255, 255, .05) 25%, rgba(255, 255, 255, .05) 26%, transparent 27%, transparent 74%, rgba(255, 255, 255, .05) 75%, rgba(255, 255, 255, .05) 76%, transparent 77%, transparent);
    height:100%;
    background-size:50px 50px;
}

.site-title {
    font-size: 26px;
    font-weight: bolder;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;
    text-transform: uppercase; 
    color: $brand-color;
}
.menu-close{
    position: absolute;
    top: 5px;
    font-size: 30px;
    right:22px;
    display: none;
}
.site-nav {
    float: right;
    line-height: 56px;

    .menu-icon {
        display: none;
    }
    .page-link {
        color: $grey-color-light;
        line-height: $base-line-height;

        // Gaps between nav items, but not on the last one
        &:not(:last-child) {
            margin-right: 20px;
        }
    }
    .trigger li{
        list-style: none;
        display: inline-block;
        margin: 0 0 0 10px;
        a{
            color: $pallet-color;
        }
    }
    @include media-query($on-laptop) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;
        z-index: 4;
        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;
            > svg {
                width: 18px;
                height: 15px;
                path {
                    fill: $pallet-color;
                }
            }
        }
        .trigger {
            clear: both;
            display: none;
            margin-top: 30px;
            li{
                display: block;
                margin: 0;
                text-align: center;
                padding: 0 20px;
                &:hover a{
                    color: $brand-color;
                }
            }
        }
        &.active { 
            .trigger {
                display: block;
                padding-bottom: 5px;
            }
            .menu-icon{
                display: none;
                z-index: 0;
            }
            + .menu-close {
                display: block;
                z-index: 5;
            }
        }
        .page-link {
            display: block;
            padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 20px;
        }
    }
}