/**
* Page content
*/
.page-content {
    color:#333333;
    padding: 0 0 $spacing-unit;
    flex: 1;
    .post-title h1{
        display:none;
    }
}
.page-heading {
    font-size: 20px;
}

/**
* Post content
*/
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 42px;
    text-transform: uppercase;
    font-weight: bold;
    @include media-query($on-laptop) {
        font-size: 36px;
    }
}
.post-content {
    margin-bottom: $spacing-unit;
    h2 {
        font-size: 32px;
        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }
    h3 {
        font-size: 26px;
        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }
    h4 {
        font-size: 20px;
        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
    img{
        display: block;
        margin: 10px auto 40px;
        max-height: 250px;
    }
}