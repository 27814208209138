
/**
* HOME Page
*/
.home{
    h1{
        font-size: 45px;
        font-weight: bolder;
        line-height: 56px;
        letter-spacing: -1px;
        text-transform: uppercase;
        color: $pallet-color;
        padding: 20px 0;
        @include media-query($on-laptop) {
            font-size: 24px;
            text-align: center;
        }
    }
    .service{
        h2{
            background-color: $brand-color;
            color: #fff;
            i.fa{
                color: #fff;
                font-size: 20px;
                margin:0 20px;
            }
            span{
                font-size: 20px;
            }
        }
        &:hover{
            h2{
                span{
                    background-position: -100% 0;
                }
            }
        }
        @include media-query($on-laptop) {
            p{
                margin: 15px 40px;
            }
        }
    }
}

.featured-portfolio{
    figure{
        height: 250px;
        position: relative;
        z-index: 0;
        &:hover,
        &:active{
            .overlay{
                width: 50%;
                height: 50%;
                margin-top: 40px;
            }
        }
        .overlay{
            position: relative;
            margin: 0 auto;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            transition: all .5s;
            z-index: 1;
        }
        figcaption{
            position: absolute;
            top: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
            header{
                display: block;
                color: $brand-color;
                text-align: center;
                line-height: 1em;
                font-size: 16px;
                margin-top: 10px;
            }
            span{
                position: absolute;
                bottom: 30px;
                width: 90%;
                text-align: center;
                margin-left: 5%;
            }
            footer{
                position: absolute;
                bottom: 5px;
                right: 10px;
                font-size: 16px;
                color: $pallet-color;
            }
        }
    }
}

.latest-post{
    display:flex;
    @include media-query($on-laptop) {
        display: block;
    }
}
.post-list {
    display:inline-block;
    margin: 20px;
    list-style: none;
    position: relative;
    @include media-query($on-laptop) {
        display: block;
        margin: auto;
    }
    > figcaption {
        margin-bottom: $spacing-unit;
        background-size: contain!important;
        background-position: center center;
        background-repeat: no-repeat;
        .post-title{
            position: relative;
            background-color: rgba(0, 0, 0, 0.6);
            transition: all .5s;
            h2{
                display: block;
                text-align: center;
                line-height: 1em;
                padding:15px 20px;
            }
            .excerpt {
                font-size: 16px;
                text-align: center;
                padding-bottom: 20px;
            }
            .link-icon{
                position: absolute;
                top: 15px;
                left: 5px;
                color: #cc9900;
                font-size: 15px;
                visibility: hidden;
            }
        }
        &:hover{
            .post-title{
                background-color: rgba(0, 0, 0, 1);
            }
            .link-icon{
                visibility: visible;
            }
        }
    }
    &.col-8 > figcaption{
        height: calc(100% - 30px);
        @include media-query($on-laptop) {
            height: 180px;
        }
    }
    &.col-4 > figcaption{
        height:120px;
        @include media-query($on-laptop) {
            height: 180px;
        }
        h2{
            font-size: 16px;
            @include media-query($on-laptop) {
                font-size: 24px;
            }
        }
    }
}

.post-meta {
    font-size: $small-font-size;
}

.post-link {
    display: block;
    font-size: 24px;
    line-height: 1.4em;
}


.date {
    color: $brand-color;
    display: block;
    font-size: 16px;
    position: relative;
    text-align: center;
    z-index: 1;
    &:before {
        border-top: 4px double $brand-color;
        content: "";
        position: absolute;
        top: 12px;
        left: 0;
        width: 100%;
        z-index: -1;
    }
    span{
        background: #000000;
        padding: 0 10px;
        text-transform: uppercase;
        display: inline-block;
        border-radius: 7px;
        border: 1px solid $brand-color;
        z-index: 1;
    }
}


.post-content{
    .post-list{
        li{
            background-size: cover;
            min-height: 250px;
            h2{
                a{
                }
            }
        }
    }
}
